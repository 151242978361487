// Lazy loaded pages for code splitting purposes
import { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import PageNotFoundRoute from '@/components/routing/PageNotFoundRoute';
import PrivateRoute from '@/components/routing/PrivateRoute';
import Redirect from '@/components/routing/RedirectWithSearch';

import RouterContext from '@/context/Router';

import route from '@/lib/routes';

import * as Views from './lazyImportedViews';

const MainRoutes = () => {
  const location = useLocation();
  const { parsedSearchParams } = useContext(RouterContext);
  return (
    <Switch>
      <PrivateRoute
        path={route('accountShow')}
        component={Views.AccountShowView}
      />

      <Route
        exact
        path={route('guestInvitationIndex')}
        component={Views.GuestInvitationView}
      />
      <PrivateRoute
        exact
        path={route('reservationIndex')}
        component={Views.ReservationIndexView}
      />
      <PrivateRoute
        exact
        path={route('reservationActiveIndex')}
        component={Views.ReservationIndexView}
      />
      <PrivateRoute
        exact
        path={route('reservationPastIndex')}
        component={Views.ReservationIndexView}
      />
      <PrivateRoute
        exact
        path={route('reservationInfo')}
        component={Views.ReservationInfoView}
      />
      <PrivateRoute
        path={route('reservationShow')}
        component={Views.ReservationShowView}
      />

      <Route path={route('signup')} component={Views.SignupIndexView} />

      {parsedSearchParams.signup && (
        <Redirect
          to={{
            pathname: route('signup'),
            search: location.search,
          }}
        />
      )}

      <Route exact path={route('index')}>
        <Redirect to={route('spaceIndex')} />
      </Route>

      <Route
        path={[route('spaceIndex'), route('roomIndex')]}
        component={Views.BookingResourcesRoutes}
      />

      <Route exact path={route('more')} component={Views.MoreView} />
      <Route exact path={route('login')} component={Views.LoginView} />
      <Route exact path={route('logout')} component={Views.LogoutView} />
      <Route
        exact
        path={route('confirmEmail')}
        component={Views.ConfirmEmailView}
      />
      <Route
        exact
        path={route('breatherSetPassword')}
        component={Views.BreatherSetPasswordView}
      />
      <Route
        exact
        path={route('forgotPassword')}
        component={Views.ForgotPasswordView}
      />
      <Route
        exact
        path={route('resetPassword')}
        component={Views.ResetPasswordView}
      />
      <Route exact path={route('rules')} component={Views.RulesView} />

      <PageNotFoundRoute />
    </Switch>
  );
};

export default MainRoutes;
