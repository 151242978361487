import localForage from 'localforage';
import moment from 'moment-timezone';

/**
 * Mark all notifications closed by the user as closed locally.
 *
 * @param {String} notificationName Name of the notification
 *
 * @return {Promise} Returns after updating closed notifications
 */
export function markNotificationAsClosed(notificationName) {
  // Pull current closedNotifications object and update it
  return localForage.getItem('closedNotifications').then((notifications) => {
    return localForage.setItem('closedNotifications', {
      ...notifications,
      [notificationName]: new Date(),
    });
  });
}

/**
 * Get all notifications closed by the user.
 *
 * @return {Promise} Returns closed notifications
 */
export async function getClosedNotifications() {
  const notifications = await localForage.getItem('closedNotifications');

  // Delete notifications older than 1 day
  return Object.keys(notifications ?? {}).reduce((acc, key) => {
    // I decided to use this past one day logic because some banners
    // can't be hidden forever. Like the maintenance banner.
    const pastOneDay = moment(notifications[key]).isBefore(
      moment().subtract(1, 'day'),
    );

    if (pastOneDay) {
      delete notifications[key];

      localForage.setItem('closedNotifications', notifications);
    }

    return { ...acc, [key]: pastOneDay };
  }, {});
}
