import styled, { css } from 'styled-components/macro';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';
import Icon from '@/components/graphics/Icon';

import units from '@/styles/js/units';
import typeStyle from '@/styles/js/fonts/typeStyles';
import { notTouch } from '@/styles/js/utils/media';
import { colors } from '@/styles/js/utils/theme';
import { rgba } from '@/styles/js/utils/colors';
import { StyledCompanyLogo, StyledCompanyFullLogo, StyledCompanyLogoMiniWhite, StyledCompanyFullImage, StyledCompanySimplifiedImage } from '@/components/brand/CompanyLogo';

export const NavLarge = styled.nav`
  border-bottom: 1px solid ${colors.rule};
  background-color: white;
`;

export const NavLogo = styled.div`
  overflow: hidden;

  &&& > a {
    display: block;
    line-height: 0;
  }

  &&& ${StyledCompanyLogo} {
    width: auto;
    height: 4rem;
    > img,
    > svg {
      height: 100%;
    }
    > svg {
      width: 100%;
    }
  }

  &&& ${StyledCompanyFullLogo} {
    width: auto;
    height: auto;
  }

  &&& ${StyledCompanySimplifiedImage},
  &&& ${StyledCompanyLogoMiniWhite} {
    display: inline-flex;
    width: 4rem;
    border-radius: 30%;
  }

  &&& ${StyledCompanyFullImage} {
    display: inline-flex;
    width: fill-available;
    > img {
      object-fit: contain;
      align-self: flex-start;
      justify-self: flex-start;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 40%;

  ${NavLogo} {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

export const BackButton = styled(Icon).attrs({
  type: 'arrow-left-24'
})`
  cursor: pointer;
`;

export const Inner = styled.div`
  padding: 0 4rem;
  height: ${units.components.navLargeHeight};
  display: flex;
  justify-content: space-between;
`;

export const Link = styled(RRLink)``;

export const NavItems = styled.div`
  display: flex;
  position: relative;
`;

export const NavItem = styled.div`
  display: flex;
`;

export const NavLink = styled(RRNavLink).attrs({
  activeClassName: 'is-active',
})`
  ${typeStyle('h4')}

  position: relative;
  font-size: 1.5rem;
  display: block;
  border-bottom: 2px solid transparent;
  padding-top: 3.3rem;
  padding-bottom: 2.8rem;
  margin-left: 4rem;
  transition: color 0.15s;
  color: ${colors.text};

  &:active {
    opacity: 0.7;
  }

  &:not(.is-active):hover {
    ${notTouch} {
      color: ${colors.accent};
    }
  }

  &.is-active {
    border-bottom-color: ${colors.primary};
  }

  ${({ $strong }) => $strong && css`
    color: ${colors.primary};
  `}

  ${({ $withAvatar }) => $withAvatar && css`
    padding-left: 3.4rem;
    position: relative;
  `}
`;

export const NotificationDot = styled.span`
  position: absolute;
  top: 2.7rem;
  right: -1.4rem;

  min-width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;

  background-color: ${colors.primaryDark};
  color: white;

  font-weight: normal;
  font-size: 0.8rem;
  line-height: 1.4rem;
  text-align: center;
`;

export const LinkAvatar = styled.div`
  width: 2.4rem;
  position: absolute;
  left: 0;
  margin-top: -0.2rem;
`;

export const ItemSubNav = styled.nav`
  position: absolute;
  right: -3rem;
  top: -9999rem;
  z-index: ${units.zIndex.siteHeader};
  width: 21.8rem;
  background-color: white;
  box-shadow: 0 0.2rem 0.9rem ${rgba('black', 0.04)};
  transition: opacity 0.3s;
  opacity: 0;
  will-change: opacity;

  ${NavItem}:hover & {
    opacity: 1;
    top: ${units.components.navLargeHeight}; // Height of the navigation
  }
`;

export const SubNavLink = styled(RRLink)`
  ${typeStyle('body')}

  border: 1px solid ${colors.rule};
  display: block;
  padding: 0 2rem;
  line-height: 5.2rem;

  & + & {
    border-top: none;
  }

  &:hover {
    color: ${colors.accent};
  }
`;
