/**
 * Field values that need to be referenced in multiple places and aren't simple
 * values.
 */

// Values used for Group Size space filter
export const capacityValueMap = new Map([
  [null, 'Any'],
  ['1', 'Just Me'],
  ['2', '2 people'],
  ['3', '3 people'],
  ['4', '4 people'],
  ['5', '5 people'],
  ['6-8', '6-8 people'],
  ['9-12', '9-12 people'],
  ['13-20', '13-20 people'],
  ['21+', '21+ people'],
]);
